import * as React from 'react';
import { ReactNode } from 'react';
import {
  IStylableButtonProps,
  IStylableButtonEventHandlers,
} from '../StylableButton.types';
import {
  activateBySpaceButton,
  activateByEnterButton,
} from '../../../core/commons/a11y';
import { TestIds } from '../contants';
import { classes, st } from './StylableButton.component.st.css';

const createIconFromString = (svg: string) => {
  return React.createElement('div', {
    dangerouslySetInnerHTML: {
      __html: svg || '',
    },
  });
};

type IStylableButtonA11yAttributes = Pick<
  React.AriaAttributes,
  'aria-pressed' | 'aria-haspopup' | 'aria-label' | 'aria-expanded'
> & {
  tabIndex: number;
};

const getAriaAttributes = ({
  pressed,
  expanded,
  haspopup,
  tabindex,
  label,
}: IStylableButtonProps['a11y'] &
  Pick<
    IStylableButtonProps,
    'label'
  >): Partial<IStylableButtonA11yAttributes> => {
  const finalAriaAttributes: Partial<IStylableButtonA11yAttributes> = {
    'aria-label': label,
  };
  if (typeof pressed === 'boolean') {
    finalAriaAttributes['aria-pressed'] = pressed;
  }

  if (typeof expanded === 'boolean') {
    finalAriaAttributes['aria-expanded'] = expanded;
  }

  if (haspopup) {
    finalAriaAttributes['aria-haspopup'] = haspopup;
  }

  if (typeof tabindex === 'number') {
    finalAriaAttributes.tabIndex = tabindex;
  }

  return finalAriaAttributes;
};

const ButtonContent: React.FC<{ icon?: ReactNode; label?: string }> = ({
  label,
  icon,
}) => (
  <div className={classes.container}>
    {label && (
      <span className={classes.label} data-testid={TestIds.buttonLabel}>
        {label}
      </span>
    )}
    {icon && (
      <span className={classes.icon} aria-hidden="true">
        {icon}
      </span>
    )}
  </div>
);

const getEventHandlers = (
  {
    onClick,
    onDblClick,
    onMouseEnter,
    onMouseLeave,
  }: Partial<IStylableButtonEventHandlers>,
  isLink: boolean,
  isDisabled: boolean,
) => {
  return {
    onKeyDown: isLink ? activateBySpaceButton : activateByEnterButton,
    onClick: !isDisabled && onClick ? onClick : undefined,
    onDoubleClick: !isDisabled && onDblClick ? onDblClick : undefined,
    onMouseEnter,
    onMouseLeave,
  };
};

const StylableButton: React.FC<IStylableButtonProps> = props => {
  const {
    id,
    link,
    svgString,
    label,
    isDisabled,
    className,
    a11y,
    onClick,
    onDblClick,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const a11yAttr = React.useMemo(() => getAriaAttributes({ label, ...a11y }), [
    a11y,
    label,
  ]);

  const eventHandlers = React.useMemo(
    () =>
      getEventHandlers(
        { onClick, onDblClick, onMouseLeave, onMouseEnter },
        !!(link && link.href),
        isDisabled,
      ),
    [isDisabled, link, onClick, onDblClick, onMouseEnter, onMouseLeave],
  );

  // TODO hasError - seems to be static in wix-ui-santa
  const rootClassName = st(
    classes.root,
    { error: false, disabled: isDisabled },
    className,
  );

  const icon: ReactNode = svgString ? createIconFromString(svgString) : null;

  const renderLinkedButton = () => (
    // TODO - Should probably render Link component for unity?
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href={isDisabled ? '#' : link!.href}
      target={link!.target}
      data-popupid={link?.linkPopupId}
      data-anchor={link?.anchorDataId}
      data-anchor-comp-id={link?.anchorCompId}
      className={st(rootClassName, classes.link)}
      data-testid={TestIds.buttonContent}
      {...a11yAttr}
      {...eventHandlers}
    >
      <ButtonContent label={label} icon={icon} />
    </a>
  );

  const renderRegularButton = () => (
    // TODO - should we reuse some Button component for unity?
    <button
      type="button"
      disabled={isDisabled}
      className={rootClassName}
      data-testid={TestIds.buttonContent}
      {...a11yAttr}
      {...eventHandlers}
    >
      <ButtonContent label={label} icon={icon} />
    </button>
  );

  const buttonContent =
    link && link.href ? renderLinkedButton() : renderRegularButton();

  return <div id={id}>{buttonContent}</div>;
};

export default StylableButton;
