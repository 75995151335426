
  var rt = require("@stylable/runtime/cjs/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "StylableButton1196941481",
      {"classes":{"root":"StylableButton1196941481__root","link":"StylableButton1196941481__link","container":"StylableButton1196941481__container","label":"StylableButton1196941481__label","icon":"StylableButton1196941481__icon"},"vars":{},"stVars":{},"keyframes":{}},
      "",
      -1,
      module.id
  );
  